//Use variable bc positions can be auto-calculated
//Based off the root handle.

let handleSize = 8; //CHANGED
let handleLongSize = 120; //CHANGED
let handleX = -64; //CHANGED
let handleY = 64; //CHANGED

let guardHeight = handleSize; //CHANGED
let guardWidth = 12; //CHANGED
let guardX = handleX + handleLongSize; //CHANGED
let guardY = handleY - guardHeight / 2 + handleSize / 2; //CHANGED

let connectX = handleX + handleLongSize; //CHANGED
let connectY = handleY; //CHANGED
let connectLength = 80; //CHANGED

let spearX = connectX + connectLength; //CHANGED
let spearY = connectY + handleSize / 2; //CHANGED
let spearWidth = 20; //CHANGED
let spearLength = 48; //CHANGED


let prong1x = guardX + guardWidth + 5; //CHANGED
let prong1y = guardY //CHANGED
let prong2x = guardX + guardWidth + 5; //CHANGED
let prong2y = guardY + guardHeight;  //CHANGED

const spetum = [
  {
    name: "Bhandle",
    type: "rectangle",
    x: handleX,
    y: handleY,
    width: handleLongSize,
    height: handleSize,
    fillStyle: "#414747",
    lineWidth: 6,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "Bspear",
    type: "polygon",
    points: [
      { x: spearX, y: spearY },
      { x: spearX - spearLength, y: spearY + spearWidth / 2 },
      { x: spearX - spearLength, y: spearY - spearWidth / 2 },
    ],
    xTrans: 0,
    yTrans: 0,
    fillStyle: "#818282",
    lineWidth: 8,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "Bconnect",
    type: "polygon",
    points: [
      { x: connectX, y: connectY },
      { x: connectX, y: connectY + handleSize },
      { x: connectX + connectLength, y: connectY + handleSize / 2 },
    ],
    xTrans: 0,
    yTrans: 0,
    fillStyle: "#A0A8A8",
    lineWidth: 8,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "Bguard",
    type: "rectangle",
    x: guardX,
    y: guardY,
    width: guardWidth,
    height: guardHeight,
    fillStyle: "#5F6464",
    lineWidth: 4,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "prong1",
    type: "polygon",
    points: [
      { x: prong1x, y: prong1y },
      { x: prong1x + 6.4, y: prong1y },
      { x: prong1x + 6.4 + 16, y: prong1y - 20 },
      { x: prong1x + 16, y: prong1y - 20 },
    ],

    xTrans: 0,
    yTrans: 0,
    fillStyle: "#5F6464",
    lineWidth: 8,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "tip1",
    type: "polygon",
    points: [
      { x: prong1x + 16, y: prong1y - 20 - 1.6 },
      { x: prong1x + 16, y: prong1y - 12 - 1.6 },
      { x: prong1x + 36, y: prong1y - 16 - 1.6 },
    ],

    xTrans: 0,
    yTrans: 0,
    fillStyle: "#A0A8A8",
    lineWidth: 8,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "prong2",
    type: "polygon",
    points: [
      { x: prong2x, y: prong2y },
      { x: prong2x + 6.4, y: prong2y },
      { x: prong2x + 6.4 + 16, y: prong2y + 20 },
      { x: prong2x + 16, y: prong2y + 20 },
    ],
    xTrans: 0,
    yTrans: 0,
    fillStyle: "#5F6464",
    lineWidth: 8,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "tip2",
    type: "polygon",
    points: [
      { x: prong2x + 16, y: prong2y + 20 + 1.6 },
      { x: prong2x + 16, y: prong2y + 12 + 1.6 },
      { x: prong2x + 36, y: prong2y + 16 + 1.6 },
    ],
    xTrans: 0,
    yTrans: 0,
    fillStyle: "#A0A8A8",
    lineWidth: 8,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
];

export default spetum;
