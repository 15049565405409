import EnemyNames from "../../constants/EnemyNames";

import sullenInfo from "./sullen/sullen.js";
import bouldaInfo from "./boulda/boulda.js";

const allEnemies = {
  [EnemyNames.SULLEN]: sullenInfo,
  [EnemyNames.BOULDA]: bouldaInfo,
};

export default allEnemies;
