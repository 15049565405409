import styles from "./LoadingScreen.module.css"

function LoadingScreen(){
    return (
      <div className={styles.container}>
        <b>Loading...</b>
      </div>
    );
}

export default LoadingScreen;