import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants.js";

import remiImage from "../../../assets/champions/mykola/avatar.png"
import drawInfo from "./drawInfo.js";
import attackAnimation from "./animations/attackAnimation.js";
import qAnimation from "./animations/qAnimation.js";
import eAnimation from "./animations/eAnimation.js";
import ultimateAnimation from "./animations/ultimateAnimation.js";
import deathAnimation from "../damageAnimations/deathAnimation.js";
import mykolaAbilities from "./abilities/mykolaAbilities.js";



const remiInfo = {
  drawing: drawInfo,
  animations: {
    [ObjectStateConstants.ATTACKING]: attackAnimation,
    [ObjectStateConstants.CASTING_Q]: qAnimation,
    [ObjectStateConstants.CASTING_E]: eAnimation,
    [ObjectStateConstants.CASTING_R]: ultimateAnimation,
    [ObjectStateConstants.STUNNED]: { animation: [] },
    [ObjectStateConstants.DEATH]: deathAnimation,
  },
  abilities: mykolaAbilities,
  image: remiImage,
  name: "Mykola",
  biography: `In a land torn by ceaseless raids, 
  Mykola's people forged their war arts in order to survive.
  But alone he wanders into the Terrace, what powers could
  he seek?`,
};

export default remiInfo;
