import store from "../../redux/store";
import allChampions from "../champions/champions";
import allEnemies from "../enemies/enemies";
import allProjectiles from "../projectiles/projectiles";
import allTrails from "../trails/allTrails";
import trailManager from "./TrailManager"
import damageShakeAnimation from "../champions/damageAnimations/damageShakeAnimation";
import {ORIGINAL_COLOR, damageColorsAnimation} from "../champions/damageAnimations/damageColorsAnimation";
import gsap from "gsap";
import { CustomEase } from "gsap/all";
import { ObjectStateConstants, ObjectTypes } from "../../constants/AnimationObjectConstants";
import { hexToRGBA, copyTransferRGBAValues } from "../utils/colorConvertFunctions";

const objectDatafileMap = {
  [ObjectTypes.PLAYER]: allChampions,
  [ObjectTypes.ENEMY]: allEnemies,
  [ObjectTypes.PROJECTILE]: allProjectiles,
  [ObjectTypes.TRAIL]: allTrails,
};
const storedObjectsGetterMap = {
  [ObjectTypes.PLAYER]: ()=>store.getState().game.gameState.players,
  [ObjectTypes.ENEMY]: ()=>store.getState().game.gameState.enemies,
  [ObjectTypes.PROJECTILE]: ()=>store.getState().game.gameState.projectiles,
  [ObjectTypes.TRAIL]: ()=>trailManager.getTrails(),
};

export class AnimationManager {
  constructor(ObjectType) {
    this.animators = {};
    this.allObjectsData = objectDatafileMap[ObjectType];
    this.objectType = ObjectType;
  }

  update() {
    this._removeUnusedAnimators();
    this._handleAnimatorsChanges();
  }
  _removeUnusedAnimators() {
    const storedObjects = storedObjectsGetterMap[this.objectType]();
    //TODO: Function is inefficient as long
    // as server does not return proximity players
    const animatorIds = Object.keys(this.animators);
    for (let id of animatorIds) {
      let matchingObjectFound = false;
      for (let object of storedObjects){
        // Matching Object found but in Death State = Delayed Animator Removal
        if (id === object.id && object.state == ObjectStateConstants.DEATH){
          matchingObjectFound = true;
          setTimeout(()=>{this._deleteAnimator(id)},2500) ;
          break; //todo: right now deleteAnimator is queued up a lot of times, we should set a flag to limit this
        }
        // Matching Object = Don't Remove Animator
        else if (id === object.id){
          matchingObjectFound = true;
          break;
        }
      }
      if (matchingObjectFound === false){
        this._deleteAnimator(id)
      }
    }
  }
  _handleAnimatorsChanges() {
    const storedObjects = storedObjectsGetterMap[this.objectType]();
    for (let object of storedObjects) {
      if (!(object.id in this.animators) && object.state != ObjectStateConstants.DEATH) {
        this._handleNewAnimator(object);
      } else {
        this._handleExistingAnimator(object);
      }
    }
  }

  _handleNewAnimator(object) {
    this._addAnimator(object);
    //trigger an animation if the animator is added & its already casting.
    if (object.state && object.state != ObjectStateConstants.IDLE) {
      this._triggerAnimation(object.id);
    }
  }
  _handleExistingAnimator(object) {
    this._handleAnimatorStateChanges(object.id, object.state);
    this._handleObjectChanges(object);
    if (this.objectType == ObjectTypes.PLAYER || this.objectType == ObjectTypes.ENEMY) {
      this._handleHealthChanges(object.id, object.health);
    }
  }

  _addAnimator(object) {
    const animator = {
      id: object.id,
      state: object.state,
      health: object.health ? object.health : 1,
      objectName: object.objectName,
      animationOverride: false,
      isAnimating: false,
      parts: this._createAnimatorParts(object.objectName),
    };

    this.animators[object.id] = animator;
  }
  _handleAnimatorStateChanges(id, newState) {
    if (id in this.animators) {
      let animator = this.getAnimator(id);
      let oldState = animator.state;
      //Check if Pause Occurred
      if (newState == null){
        animator.state = ObjectStateConstants.ANIMATING;
      }
      //TODO: This logic also triggers when state is set to ANIMATING
      //Check if State Changed
      if (newState && (newState != oldState) ) {
        animator.state = newState;
        if (newState == ObjectStateConstants.IDLE) {
          this._resetAnimator(animator.id);
        } else if (newState != ObjectStateConstants.IDLE) {
          this._triggerAnimation(id);
        }
      }
    }
  }
  _handleHealthChanges(id, newHealth) {
    if (id in this.animators) {
      let animator = this.getAnimator(id);
      let oldHealth = animator.health;
      //Check if Health Changed
      if (oldHealth != newHealth) {
        animator.health = newHealth;
        if (newHealth < oldHealth) {
          let healthChange = oldHealth - newHealth
          this._triggerDamageAnimations(id, healthChange);
        }
      }
    }
  }
  _handleObjectChanges(object) {
    //For when the object changes names currently. LUCCA_BOAR -> LUCCA_SPETUM
    let animator = this.getAnimator(object.id);
    if (animator && animator.objectName != object.objectName) {
      animator.objectName = object.objectName;
      this._reassignAnimatorColors(animator)
    }
  }

  _reassignAnimatorColors(animator){
    const objectParts = this.allObjectsData[animator.objectName].drawing.shapes;
    for (let part of objectParts) {
      let hexFillStyle = part.fillStyle ? part.fillStyle : "#FF0000FF";
      let hexStrokeStyle = part.strokeStyle ? part.strokeStyle : "#000000FF";
      animator.parts[part.name].color = hexToRGBA(hexFillStyle);
      animator.parts[part.name].originalColor = hexToRGBA(hexFillStyle);
      animator.parts[part.name].strokeColor = hexToRGBA(hexStrokeStyle);
      animator.parts[part.name].originalStrokeColor = hexToRGBA(hexStrokeStyle);
    }
  }

  _createAnimatorParts(objectName) {
    const objectParts = this.allObjectsData[objectName].drawing.shapes;
    let animatorParts = {};
    for (let part of objectParts) {
      let hexFillStyle = part.fillStyle ? part.fillStyle : "#FF0000FF";
      let hexStrokeStyle = part.strokeStyle ? part.strokeStyle : "#000000FF";

      let defaultConfig = {
        shiftX: 0,
        shiftY: 0,
        damageShiftX : 0,
        damageShiftY : 0,
        rotation: 0,
        scale: 1,
        color: hexToRGBA(hexFillStyle),
        originalColor: hexToRGBA(hexFillStyle),
        strokeColor: hexToRGBA(hexStrokeStyle),
        originalStrokeColor: hexToRGBA(hexStrokeStyle),
      };
      animatorParts[part.name] = defaultConfig;
    }
    return animatorParts;
  }
  //TODO: Consider if this should be part of a Animator method
  _triggerAnimation(objectId) {
    let animator = this.getAnimator(objectId);
    let animationObject = this.allObjectsData[animator.objectName].animations;
    //Access the specific animation that matches state.
    let animation = animationObject[animator.state].animation;

    for (let part of animation) {
      if (animationObject[animator.state].applyToAllParts) {
        this._executeAnimationProcessToAllParts(part, animator);
      } else {
        this._executeAnimationProcess(part.name, part, animator);
      }
    }
  }

  _executeAnimationProcessToAllParts(animationPart, animator){
    const objectParts = this.allObjectsData[animator.objectName].drawing.shapes;
    for (let objectPart of objectParts) {
      this._executeAnimationProcess(objectPart.name, animationPart, animator);
    }
  }
  _executeAnimationProcess(bodyPartName, animationPart, animator){
      let dials = animator.parts[bodyPartName];
      //Color Transition
      if (animationPart.fillStyle) {
        let destColor = hexToRGBA(animationPart.fillStyle);
        const endColorResult = {
          r: destColor.r,
          g: destColor.g,
          b: destColor.b,
          a: destColor.a,
          duration: animationPart.duration / 1000,
          delay: animationPart.delay / 1000,
          ease: "power1.out",
        };
        gsap.to(dials.color, endColorResult);
      }
      //BorderColor Transition
      if (animationPart.strokeStyle) {
        let destStrokeColor = hexToRGBA(animationPart.strokeStyle);
        const endStrokeColorResult = {
          r: destStrokeColor.r,
          g: destStrokeColor.g,
          b: destStrokeColor.b,
          a: destStrokeColor.a,
          duration: animationPart.duration / 1000,
          delay: animationPart.delay / 1000,
          ease: "power1.out",
        };
        gsap.to(dials.strokeColor, endStrokeColorResult);
      }


      //TODO:: each one of the fields should be only set if declared
      // Why: So in the animation file we don't have to re-declare .rotation etc, to keep future
      // parts aligned with past animation parts.
      const endResult = {
        rotation: animationPart.rotation,
        duration: animationPart.duration / 1000,
        shiftX: animationPart.x,
        shiftY: animationPart.y,
        delay: animationPart.delay / 1000,
        // ease: CustomEase.create("custom", "M0,0 C0.946,0.981 0.879,0.981 1,1 "),
        ease: "linear",
      };
      //Optional Scale Setting
      if (animationPart.scale) {
        endResult.scale = animationPart.scale;
      }
      //GSAP Transition
      gsap.to(dials, endResult);
  }


  _triggerDamageAnimations(objectId, healthChange){
    let animator = this.getAnimator(objectId);
    this._triggerDamageShake(animator, healthChange);
    this._triggerDamageColors(animator);
  }
  _triggerDamageShake(animator, healthChange){
    let partNames = Object.keys(animator.parts);
    for (let part of damageShakeAnimation.animation) {
      for (let name of partNames) {
        let dials = animator.parts[name];
        const endResult = {
          damageShiftX: (part.damageShiftX * healthChange) / 30,
          damageShiftY: (part.damageShiftY * healthChange) / 30,
          duration: part.duration / 1000,
          delay: part.delay / 1000,
          ease : "linear",
        };
        //GSAP Transition
        gsap.to(dials, endResult);
      }
    }
  }
  _triggerDamageColors(animator){
    let partNames = Object.keys(animator.parts);
    for (let part of damageColorsAnimation.animation) {
      for (let name of partNames) {
        let dials = animator.parts[name];

        //Detect if we want every color to go back to normal
        let destColor = {};
        copyTransferRGBAValues(dials.originalColor,destColor);
        if (part.fillStyle != ORIGINAL_COLOR){  
          destColor = hexToRGBA(part.fillStyle);
        }
        
        const endColorResult = {
          r: destColor.r,
          g: destColor.g,
          b: destColor.g,
          a: destColor.a,
          duration: part.duration / 1000,
          delay: part.delay / 1000,
          ease: "power2.out",
        };
        //GSAP Transition
        gsap.to(dials.color, endColorResult);
      };
    }
  }
  _resetAnimator(objectId) {
    let animator = this.getAnimator(objectId);
    for (let key in animator.parts) {
      let dials = animator.parts[key];
      dials.rotation = 0;
      dials.shiftX = 0;
      dials.shiftY = 0;
      dials.scale = 1;
      copyTransferRGBAValues(dials.originalColor, dials.color)
      copyTransferRGBAValues(dials.originalStrokeColor, dials.strokeColor);
    }
  }
  getAnimator(id) {
    return this.animators[id];
  }
  _deleteAnimator(id) {
    delete this.animators[id];
  }

  flushAnimationManager(){
    this.animators = {};
  }
}

//TODO: Make one for PLAYER and PROJECTILE
export const playerAnimationManager = new AnimationManager(ObjectTypes.PLAYER);
export const enemyAnimationManager = new AnimationManager(ObjectTypes.ENEMY);
export const projectileAnimationManager = new AnimationManager(ObjectTypes.PROJECTILE)
export const trailAnimationManager = new AnimationManager(ObjectTypes.TRAIL);