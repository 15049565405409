import styles from "./Ability.module.css";
import Cooldown from "./Cooldown/Cooldown";
import AbilityClick from "../AbilityClick/AbilityClick";
import { useSelector } from "react-redux";

export default function Ability({awayNum, imageSource, text, ability}) {

    //Gap is 1/3 width 
    const width = 56;
    const gap = Math.round(width/3)
    // Position by default is -2 spots from center.
    // width + gap/2 sets it exactly at -1 spot.
    // +gap + width sets it -1 spot to -2.
    // -awayNum * (gap + width) controls the exact spot where it lands. 
    // awayNum specifically 
    let leftPos = `calc(50% - ${width + gap/2 + 1*(gap+width) - awayNum * (gap+width)}px`

    let cooldowns = useSelector((state)=>state.userData.cooldowns)
    let curTime = Date.now()

  return (
    <div style={{ left: leftPos }} className={styles.container}>
      <img className={styles.image} src={imageSource} />
      <div className={styles.gloss1}></div>
      <div className={styles.label}>{text}</div>
      { (curTime < cooldowns[ability]) ? <Cooldown ability={ability}/> : null  }
      <AbilityClick abilityKey={ability}/>
      
    </div>
  );
}
