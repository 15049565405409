import { RGBAtoHex } from "../utils/colorConvertFunctions";
//todo: seems weird to constantly shift between rgb and hex idk. 
// But I'd rather use hex in data files and rgb in our animator.

function getAnimatorValuesFromShape(animator, shape) {
  const animatorFillStyle =
    animator &&
    animator.parts[shape.name]
      ? RGBAtoHex(animator.parts[shape.name].color)
      : null;
  const animatorStrokeStyle =
    animator && animator.parts[shape.name]
      ? RGBAtoHex(animator.parts[shape.name].strokeColor)
      : null;
  const animationRot =
    animator && animator.parts[shape.name]
      ? animator.parts[shape.name].rotation
      : 0;
  const animShiftX =
    animator && animator.parts[shape.name]
      ? animator.parts[shape.name].shiftX
      : 0;
  const animShiftY =
    animator && animator.parts[shape.name]
      ? animator.parts[shape.name].shiftY
      : 0;
  const animScale =
    animator && animator.parts[shape.name]
      ? animator.parts[shape.name].scale
      : 1;

  return {animatorFillStyle, animatorStrokeStyle, animationRot, animShiftX, animShiftY, animScale}
}

function getDamageAnimatorValues(animator, shape) {
  const damageShiftX = animator && animator.parts[shape.name] && animator.parts[shape.name].damageShiftX ? animator.parts[shape.name].damageShiftX : 0;
  const damageShiftY = animator && animator.parts[shape.name] && animator.parts[shape.name].damageShiftY ? animator.parts[shape.name].damageShiftY : 0;
  return {damageShiftX, damageShiftY}
}


function drawShapeWithAnimators(ctx, shape, animator) {
  // Get animation values for potential transforms.
  const {
    animatorFillStyle,
    animatorStrokeStyle,
    animationRot,
    animShiftX,
    animShiftY,
    animScale,
  } = getAnimatorValuesFromShape(animator, shape);
  const { damageShiftX, damageShiftY } = getDamageAnimatorValues(
    animator,
    shape
  );

  //Color Overrides
  ctx.fillStyle = animatorFillStyle ? animatorFillStyle : shape.fillStyle;
  ctx.strokeStyle = animatorStrokeStyle ? animatorStrokeStyle : shape.strokeStyle;

  // Transformations
  ctx.rotate(shape.rotation + animationRot);
  switch (shape.type) {
    case "circle":
      // Outline Circle
      ctx.lineWidth = shape.lineWidth;
      ctx.beginPath();
      ctx.arc(
        (shape.x + animShiftX + damageShiftX) * animScale,
        (shape.y + animShiftY + damageShiftY) * animScale,
        shape.radius * animScale,
        0,
        shape.arc ? shape.arc : 2 * Math.PI
      );
      ctx.stroke();
      ctx.fill();
      ctx.closePath();
      break;
    case "ellipse":
      // Outline Ellipse
      ctx.lineWidth = shape.lineWidth;
      ctx.beginPath();
      ctx.ellipse(
        (shape.x + animShiftX + damageShiftX) * animScale,
        (shape.y + animShiftY + damageShiftY) * animScale,
        shape.radiusX * animScale,
        shape.radiusY * animScale,
        shape.selfRotation,
        shape.startAngle,
        shape.endAngle
      );
      ctx.stroke();
      // Fill Ellipse
      ctx.fill();
      ctx.closePath();
      break;
    case "rectangle":
      // Fill Rectangle
      ctx.lineWidth = shape.lineWidth;

      ctx.beginPath();
      ctx.rect(
        (shape.x + animShiftX + damageShiftX) * animScale,
        (shape.y + animShiftY + damageShiftY) * animScale,
        shape.width * animScale,
        shape.height * animScale
      );
      ctx.stroke();
      ctx.fill();
      break;

    case "line":
      // Draw Line
      ctx.beginPath();
      ctx.lineWidth = shape.lineWidth;
      ctx.moveTo(
        (shape.x1 + animShiftX + damageShiftX) * animScale,
        (shape.y1 + animShiftY + damageShiftY) * animScale
      );
      ctx.lineTo(
        (shape.x2 + animShiftX + damageShiftX) * animScale,
        (shape.y2 + animShiftY + damageShiftY) * animScale
      );
      ctx.stroke();
      break;
    case "polygon":
      ctx.lineWidth = shape.lineWidth;

      let startingX =
        (shape.points[0].x + shape.xTrans + animShiftX + damageShiftX) *
        animScale;
      let startingY =
        (shape.points[0].y + shape.yTrans + animShiftY + damageShiftY) *
        animScale;

      ctx.beginPath();
      ctx.moveTo(startingX, startingY);
      for (let point of shape.points) {
        ctx.lineTo(
          (point.x + shape.xTrans + animShiftX + damageShiftX) * animScale,
          (point.y + shape.yTrans + animShiftY + damageShiftY) * animScale
        );
      }
      ctx.lineTo(startingX, startingY);
      ctx.stroke();
      ctx.fill();
      break;
    case "unique":
      //Convenience Variables
      let xTrans = shape.xTrans ? shape.xTrans : 0;
      let yTrans = shape.yTrans ? shape.yTrans : 0;

      let startX = (shape.x + xTrans + animShiftX + damageShiftX) * animScale;
      let startY = (shape.y + yTrans + animShiftY + damageShiftY) * animScale;
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineWidth = shape.lineWidth;

      for (let point of shape.points) {
        if (point.type == "quadratic") {
          ctx.quadraticCurveTo(
            (point.controlX + xTrans + animShiftX + damageShiftX) * animScale,
            (point.controlY + yTrans + animShiftY + damageShiftY) * animScale,
            (point.x + xTrans + animShiftX + damageShiftX) * animScale,
            (point.y + yTrans + animShiftY + damageShiftY) * animScale
          );
        } else if (point.type == "line") {
          ctx.lineTo(
            (point.x + xTrans + animShiftX + damageShiftX) * animScale,
            (point.y + yTrans + animShiftY + damageShiftY) * animScale
          );
        }
      }
      ctx.stroke();
      ctx.fill();
      break;
    default:
      break;
  }
  //Transformations Finish
  ctx.rotate(-(shape.rotation + animationRot));
}

export default drawShapeWithAnimators;