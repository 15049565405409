let scale = 0.6;

let xTrans = -6;
let yTrans = 40;
let extraRot = 60

let bowDrawInfo = [
  {
    name: "recurveBow",
    type: "unique",
    fillStyle: "#581D1B",
    lineWidth: 8,
    strokeStyle: "#170305",
    x: 0,
    y: 0,
    xTrans: xTrans,
    yTrans: yTrans,
    rotation: (Math.PI * (270 + extraRot)) / 180,
    points: [
      {
        type: "quadratic",
        controlX: 10 * scale,
        controlY: -10 * scale,
        x: 20 * scale,
        y: 0,
      },
      {
        type: "quadratic",
        controlX: 50 * scale,
        controlY: 20 * scale,
        x: 80 * scale,
        y: 0,
      },
      {
        type: "quadratic",
        controlX: 90 * scale,
        controlY: -10 * scale,
        x: 120 * scale,
        y: -10 * scale,
      },
      {
        type: "quadratic",
        controlX: 130 * scale,
        controlY: -10 * scale,
        x: 120 * scale,
        y: -20 * scale,
      },
      {
        type: "quadratic",
        controlX: 80 * scale,
        controlY: -20 * scale,
        x: 70 * scale,
        y: 0,
      },
      {
        type: "quadratic",
        controlX: 50 * scale,
        controlY: 10 * scale,
        x: 30 * scale,
        y: 0,
      },
      {
        type: "quadratic",
        controlX: 10 * scale,
        controlY: -30 * scale,
        x: -10 * scale,
        y: 0,
      },
      {
        type: "quadratic",
        controlX: -30 * scale,
        controlY: 10 * scale,
        x: -50 * scale,
        y: 0,
      },
      {
        type: "quadratic",
        controlX: -60 * scale,
        controlY: -20 * scale,
        x: -100 * scale,
        y: -20 * scale,
      },
      {
        type: "quadratic",
        controlX: -110 * scale,
        controlY: -10 * scale,
        x: -100 * scale,
        y: -10 * scale,
      },
      {
        type: "quadratic",
        controlX: -70 * scale,
        controlY: -10 * scale,
        x: -60 * scale,
        y: 0,
      },
      {
        type: "quadratic",
        controlX: -30 * scale,
        controlY: 20 * scale,
        x: 0,
        y: 0,
      },
    ],
  },
  {
    name: "drawString",
    type: "line",
    lineWidth: 1,
    strokeStyle: "#000000",
    x1: -100 * scale + xTrans,
    y1: -10 * scale - yTrans + 20,
    x2: 120 * scale + xTrans,
    y2: -10 * scale - yTrans + 20,
    rotation: (Math.PI * (-270 + extraRot)) / 180,
  },
];

export default bowDrawInfo;
