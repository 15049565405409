import allEnemies from "../enemies/enemies";
import { enemyAnimationManager } from "../model/AnimationManager";
import drawShapeWithAnimators from "./drawShapeWithAnimators";
import { drawHealthBar, alignCanvasOnObject, unalignCanvasFromObject } from "./utility/renderUtilities";

function renderEnemy(ctx, serverEnemyData) {
  drawHealthBar(ctx, serverEnemyData)
  let enemyAnimator = enemyAnimationManager.getAnimator(serverEnemyData.id);
  alignCanvasOnObject(ctx, serverEnemyData);
  const clientEnemyData = allEnemies[serverEnemyData.objectName];
  //todo: should be some sort of user/admin setting for this
  // renderHitbox(ctx, clientProjData);
  for (let shape of clientEnemyData.drawing.shapes) {
    drawShapeWithAnimators(ctx, shape, enemyAnimator);
  }
  unalignCanvasFromObject(ctx, serverEnemyData);
}

export default renderEnemy;
