const scale = 0.3;

const xTrans = -40/2 * scale
const yTrans = -(130 / 2) * scale;

let hatDrawInfo = [
  {
    name: "hatBow",
    type: "unique",
    fillStyle: "#581D1B",
    lineWidth: 8,
    strokeStyle: "#170305",
    x: 0 * scale,
    y: 200 * scale,
    xTrans: xTrans,
    yTrans: yTrans,
    rotation: (Math.PI * 270) / 180,
    points: [
      {
        type: "quadratic",
        x: 40 * scale,
        y: 200 * scale,
        controlX: 20 * scale,
        controlY: 240 * scale,
      },
      {
        type: "line",
        x: 140 * scale,
        y: 0,
      },
      {
        type: "quadratic",
        x: 110 * scale,
        y: -40 * scale,
        controlX: 160 * scale,
        controlY: -40 * scale,
      },
      {
        type: "line",
        x: -80 * scale,
        y: -40 * scale,
      },
      {
        type: "quadratic",
        x: -100 * scale,
        y: 0,
        controlX: -130 * scale,
        controlY: -40 * scale,
      },
      {
        type: "line",
        x: 0,
        y: 200 * scale,
      },
    ],
  },
  {
    name: "hatShadow",
    type: "unique",
    fillStyle: "#422213",
    lineWidth: 4,
    strokeStyle: "#170305",
    x: 0 * scale,
    y: -10 * scale,
    xTrans: xTrans,
    yTrans: yTrans,
    rotation: (Math.PI * 270) / 180,
    points: [
      {
        type: "line",
        x: 40 * scale,
        y: -10 * scale,
      },
      {
        type: "quadratic",
        x: 90 * scale,
        y: 50 * scale,
        controlX: 90 * scale,
        controlY: -10 * scale,
      },
      {
        type: "quadratic",
        x: 60 * scale,
        y: 120 * scale,
        controlX: 90 * scale,
        controlY: 70 * scale,
      },
      {
        type: "quadratic",
        x: 40 * scale,
        y: 130 * scale,
        controlX: 50 * scale,
        controlY: 130 * scale,
      },
      {
        type: "quadratic",
        x: 20 * scale,
        y: 170 * scale,
        controlX: 30 * scale,
        controlY: 130 * scale,
      },
      {
        type: "quadratic",
        x: 0 * scale,
        y: 130 * scale,
        controlX: 10 * scale,
        controlY: 130 * scale,
      },
      {
        type: "line",
        x: -20 * scale,
        y: 110 * scale,
      },
      {
        type: "quadratic",
        x: -40 * scale,
        y: 10 * scale,
        controlX: -70 * scale,
        controlY: 50 * scale,
      },
      {
        type: "quadratic",
        x: 0,
        y: -10 * scale,
        controlX: -20 * scale,
        controlY: -10 * scale,
      },
    ],
  },
  {
    name: "hatCenter",
    type: "unique",
    fillStyle: "#8b4728",
    lineWidth: 4,
    strokeStyle: "#170305",
    x: 0,
    y: 0,
    xTrans: xTrans,
    yTrans: yTrans,
    rotation: (Math.PI * 270) / 180,
    points: [
      {
        type: "line",
        x: 40 * scale,
        y: 0,
      },
      {
        type: "quadratic",
        x: 80 * scale,
        y: 40 * scale,
        controlX: 80 * scale,
        controlY: 0,
      },
      {
        type: "quadratic",
        x: 40 * scale,
        y: 120 * scale,
        controlX: 80 * scale,
        controlY: 80 * scale,
      },
      {
        type: "quadratic",
        x: 0,
        y: 120 * scale,
        controlX: 20 * scale,
        controlY: 140 * scale,
      },
      {
        type: "quadratic",
        x: -40 * scale,
        y: 40 * scale,
        controlX: -40 * scale,
        controlY: 80 * scale,
      },
      {
        type: "quadratic",
        x: 0,
        y: 0,
        controlX: -40 * scale,
        controlY: 0,
      },
    ],
  },

  {
    name: "hatFeather",
    type: "unique",
    fillStyle: "#c6e2ef",
    lineWidth: 4,
    strokeStyle: "#170305",
    x: -30 * scale,
    y: 40 * scale,
    xTrans: xTrans,
    yTrans: yTrans,
    rotation: (Math.PI * 270) / 180,
    points: [
      {
        type: "quadratic",
        x: 20 * scale,
        y: 40 * scale,
        controlX: 0,
        controlY: 80 * scale,
      },
      {
        type: "quadratic",
        x: -20 * scale,
        y: -100 * scale,
        controlX: 40 * scale,
        controlY: -20 * scale,
      },
      {
        type: "quadratic",
        x: -20 * scale,
        y: 20 * scale,
        controlX: 20 * scale,
        controlY: 20 * scale,
      },
      {
        type: "quadratic",
        x: -30 * scale,
        y: 40 * scale,
        controlX: -40 * scale,
        controlY: 20 * scale,
      },
    ],
  },
];

export default hatDrawInfo;
