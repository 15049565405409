////CASTING_R
//////1.
let flipDur = 500;
//////2.
let spinDelay = flipDur;
let spinDur = 6000;
let spinRot1 = -360*22;

//////3.
let unFlipDelay = spinDelay + spinDur
//////4.
let resetDelay = unFlipDelay + flipDur

let bladeColor = "#B9314F";

const ultimateAnimation = {
  animation: [
    /////////////////
    // Flip Animation
    /////////////////
    {
      name: "handle1",
      duration: flipDur,
      rotation: 0,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "blade1",
      duration: flipDur,
      rotation: 0,
      x: 0,
      y: 60,
      fillStyle: bladeColor,
      delay: 0,
    },
    {
      name: "blade2",
      duration: flipDur,
      rotation: 0,
      x: 0,
      y: 0,
      fillStyle: bladeColor,
      delay: 0,
    },
    {
      name: "blade2inside",
      duration: flipDur,
      rotation: 0,
      x: 0,
      y: 0,
      fillStyle: bladeColor,
      delay: 0,
    },
    {
      name: "guard1",
      duration: flipDur,
      rotation: 0,
      x: -27,
      y: 0,
      delay: 0,
    },
    {
      name: "ball1",
      duration: flipDur,
      rotation: 0,
      x: -27,
      y: 0,
      delay: 0,
    },
    {
      name: "ball2",
      duration: flipDur,
      rotation: 0,
      x: -27,
      y: 0,
      delay: 0,
    },
    {
      name: "blade1inside",
      duration: flipDur,
      rotation: 0,
      x: -87,
      y: 0,
      delay: 0,
    },
    ////////////////////
    // Spin Animation //
    ////////////////////
    {
      name: "arm1",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "arm2",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "handle1",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "handle2",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "blade1",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 60,
      delay: spinDelay,
    },
    {
      name: "blade2",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "guard1",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: -27,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "guard2",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "ball1",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: -27,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "ball2",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: -27,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "ball3",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "ball4",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "blade1inside",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: -87,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "blade2inside",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    //Un-FLIP
    /////////////////
    // Flip Animation
    /////////////////=
    {
      name: "blade1",
      duration: flipDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: unFlipDelay,
    },
    {
      name: "guard1",
      duration: flipDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: unFlipDelay,
    },
    {
      name: "ball1",
      duration: flipDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: unFlipDelay,
    },
    {
      name: "ball2",
      duration: flipDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: unFlipDelay,
    },
    {
      name: "blade1inside",
      duration: flipDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: unFlipDelay,
    },
    ///HARD RESET

    {
      name: "arm1",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "arm2",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "handle1",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "handle2",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "blade1",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "blade2",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "guard1",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "guard2",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "ball1",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "ball2",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "ball3",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "ball4",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "blade1inside",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "blade2inside",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
  ],
};

export default ultimateAnimation