import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants";
import deathAnimation from "../../champions/damageAnimations/deathAnimation";
import bouldaDrawing from "./bouldaDrawing";
import bouldaActiveAnimation from "./bouldaAnimation";

const bouldaInfo = {
  drawing: bouldaDrawing,
  animations: {
    [ObjectStateConstants.ACTIVE]: bouldaActiveAnimation,
    [ObjectStateConstants.DEATH] : deathAnimation
  },
  abilities: {},
};

export default bouldaInfo;
