
export function drawHealthBar(ctx, player) {
  //Healthbar
  let healthX = player.pos.x - 28;
  let healthY = player.pos.y - 65;
  let healthWidth = 50;
  let healthHeight = 2.5;

  let activeHealthWidth = Math.max((player.health / 100) * healthWidth, 0);
  ctx.fillStyle = "gray";
  ctx.fillRect(healthX, healthY, healthWidth, healthHeight);
  ctx.fillStyle = "red";
  ctx.fillRect(healthX, healthY, activeHealthWidth, healthHeight);
}

export function alignCanvasOnObject(ctx, player) {
  ctx.translate(player.pos.x, player.pos.y);
  ctx.rotate(player.rotation);
}

export function unalignCanvasFromObject(ctx, player) {
  ctx.rotate(-player.rotation);
  ctx.translate(-player.pos.x, -player.pos.y);
}
