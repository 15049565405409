import styles from "./HealthBar.module.css";
import gsap from "gsap";
import { useState, useEffect, useRef } from "react";

export default function HealthBar({ health, maxHealth }) {

  // Clamp Health Values
  maxHealth = Math.max(health, maxHealth);
  health = Math.max(health, 0);

  const [meterPercent, setMeterPercent] = useState((health / maxHealth) * 100);
  let proxy = useRef({ meterPercent });

  useEffect(() => {
    gsap.to(proxy.current, {
      meterPercent: (health / maxHealth) * 100,
      onUpdate: () => {
        setMeterPercent(proxy.current.meterPercent);
      },
      ease: "power2.out",
    });
  }, [health, maxHealth]);

  const styleMeterPercent = `${meterPercent}%`;

  return (
    <div className={styles.container}>
      <div
        style={ { width: styleMeterPercent } }
        className={styles.gloss}
      ></div>
      <div className={styles.label}>HP</div>
      <div className={styles.meter}>{health}/{maxHealth}</div>
    </div>
  );
}
