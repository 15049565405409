function interpolatePoint(x1, y1, x2, y2, proportion) {
  const x = x1 + proportion * (x2 - x1);
  const y = y1 + proportion * (y2 - y1);
  return { x, y };
}

///////////////////
//Shaft Constants//
///////////////////
const shaftHeight = 50;
const shaftWidth = 4;
const shaftX = -shaftWidth / 2;
const shaftY = -shaftHeight - 2 - 18 / 2; //connectHeight:2, headHeight:18
const shaftColor = "#ECF8F8";
/////////////////////
//Connect Constants//
/////////////////////
const connectHeight = 2;
const connectWidth = shaftWidth;
const connectX = shaftX;
const connectY = shaftY + shaftHeight;
///////////////////////
//Arrowhead Constants//
///////////////////////
const headHeight = 18;
const headWidth = 8;
const headColor = "#ECF8F8";
////Point
const headX1 = connectX + connectWidth / 2;
const headY1 = connectY + connectHeight + headHeight;
////Right
const headX2 = headX1 - headWidth / 2;
const headY2 = headY1 - headHeight;
////Left
const headX3 = headX1 + headWidth / 2;
const headY3 = headY1 - headHeight;
//////////////////
//Line Constants//
//////////////////
const sinewColor = "#F0F9F7";
const start1 = {
  x: connectX + connectWidth,
  y: connectY,
};
const start2 = {
  x: connectX,
  y: connectY,
};
let end1 = interpolatePoint(headX2, headY2, headX1, headY1, 0.3);
let end2 = interpolatePoint(headX3, headY3, headX1, headY1, 0.3);
/////////////////////
//Feather Constants//
/////////////////////
let featherHeight = 20;
let featherWidth = 4;
let featherColor = "#FFFD82";

let feather1 = {
  point1: {
    x: shaftX - shaftWidth / 2,
    y: shaftY,
  },
  point2: {
    x: shaftX - shaftWidth / 2 - featherWidth,
    y: shaftY - 0.3 * featherHeight,
  },
  point3: {
    x: shaftX - shaftWidth / 2,
    y: shaftY + 0.7 * featherHeight,
    controlX: shaftX - shaftWidth - featherWidth / 2,
    controlY: shaftY + featherHeight / 2,
  },
};
let feather2 = {
  point1: {
    x: -shaftX + shaftWidth / 2,
    y: shaftY,
  },
  point2: {
    x: -shaftX + shaftWidth / 2 + featherWidth,
    y: shaftY - 0.3 * featherHeight,
  },
  point3: {
    x: -shaftX + shaftWidth / 2,
    y: shaftY + 0.7 * featherHeight,
    controlX: -shaftX + shaftWidth + featherWidth / 2,
    controlY: shaftY + featherHeight / 2,
  },
};

const glueArrowDrawInfo = {
  shapes: [
    {
      name: "glueCircle",
      type: "circle",
      radius: 1,
      fillStyle: "#ffff0099",
      lineWidth: 20,
      strokeStyle: "olive",
      x: 0,
      y: 0,
      rotation: (0 * Math.PI) / 180,
    },
    {
      name: "shaft",
      type: "rectangle",
      x: shaftX,
      y: shaftY,
      width: shaftWidth,
      height: shaftHeight,
      fillStyle: shaftColor,
      lineWidth: 2,
      strokeStyle: "#000000",
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "connect",
      type: "rectangle",
      x: connectX,
      y: connectY,
      width: connectWidth,
      height: connectHeight,
      fillStyle: sinewColor,
      lineWidth: 2,
      strokeStyle: "#000000",
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "arrowhead",
      type: "polygon",
      points: [
        { x: headX1, y: headY1 },
        { x: headX2, y: headY2 },
        { x: headX3, y: headY3 },
      ],
      xTrans: 0,
      yTrans: 0,
      fillStyle: headColor,
      lineWidth: 2,
      strokeStyle: "#000000",
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "line1",
      type: "line",
      lineWidth: 1,
      strokeStyle: sinewColor,
      x1: start1.x,
      y1: start1.y,
      x2: end1.x,
      y2: end1.y,
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "line2",
      type: "line",
      lineWidth: 1,
      strokeStyle: sinewColor,
      x1: start2.x,
      y1: start2.y,
      x2: end2.x,
      y2: end2.y,
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "feather1",
      type: "unique",
      fillStyle: featherColor,
      lineWidth: 2,
      strokeStyle: "#000000",
      x: feather1.point1.x,
      y: feather1.point1.y,
      rotation: (Math.PI * -90) / 180,
      points: [
        { type: "line", x: feather1.point2.x, y: feather1.point2.y },
        {
          type: "quadratic",
          controlX: feather1.point3.controlX,
          controlY: feather1.point3.controlY,
          x: feather1.point3.x,
          y: feather1.point3.y,
        },
        { type: "line", x: feather1.point1.x, y: feather1.point1.y },
      ],
    },

    {
      name: "feather2",
      type: "unique",
      fillStyle: featherColor,
      lineWidth: 2,
      strokeStyle: "#000000",
      x: feather2.point1.x,
      y: feather2.point1.y,
      rotation: (Math.PI * -90) / 180,
      points: [
        { type: "line", x: feather2.point2.x, y: feather2.point2.y },
        {
          type: "quadratic",
          controlX: feather2.point3.controlX,
          controlY: feather2.point3.controlY,
          x: feather2.point3.x,
          y: feather2.point3.y,
        },
        { type: "line", x: feather2.point1.x, y: feather2.point1.y },
      ],
    },
  ],
};

export default glueArrowDrawInfo;
