import omenImage from "../../../assets/omenBlurred.png"
import futureAbilities from "./abilities/futureAbilities";

export const futureChampion1 = {
  drawing: {},
  animations: {},
  abilities: futureAbilities,
  image: omenImage,
  name: "Locked",
  biography: `...`,
  locked: true,
  //TODO: Frontend lock needs to be backend checked during deployment.
};

export default futureChampion1;
