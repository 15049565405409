import styles from "./ChampionBio.module.css";
import { useSelector, useDispatch } from "react-redux";
import allChampions from "../../../../../game/champions/champions"
import { AbilityConstants } from "../../../../../constants/AbilitySystemConstants";
import ChampionNames from "../../../../../constants/ChampionNames";
import { setSelectedAbility } from "../../../../../redux/actions/championSelect-action";

function ChampionBio() {
  const selectedChampion = useSelector((state) => state.championSelect.selectedChampion);
  const hoveredChampion = useSelector((state) => state.championSelect.hoveredChampion);
  const selectedAbility = useSelector((state) => state.championSelect.selectedAbility)
  const dispatch = useDispatch();


  function getChampionInfo() {
    if (selectedChampion != ChampionNames.NO_CHAMPION) {
      return allChampions[selectedChampion];
    } else {
      return allChampions[hoveredChampion];
    }
  }
  const champInfo = getChampionInfo();

  const name = champInfo.name
  const bio = champInfo.biography

  const abilityDescription = champInfo.abilities[selectedAbility].description;
  const abilityName = champInfo.abilities[selectedAbility].name;
  const qImage = champInfo.abilities[AbilityConstants.Q].image;
  const eImage = champInfo.abilities[AbilityConstants.E].image;
  const rImage = champInfo.abilities[AbilityConstants.R].image;

 
  function onAbilityHover(ability){
    dispatch(setSelectedAbility(ability));
  }


  return (
    <div className={styles.container}>
      <div className={styles.headerName}>
        Name: <span className={styles.resultName}>{name}</span>
      </div>
      <div className={styles.headerDescription} style={{ height: "90px" }}>
        {bio}
      </div>
      <div className={styles.headerName}>
        {selectedAbility}: <span className={styles.resultName}>{abilityName}</span>
      </div>
      <div className={styles.headerDescription}>{abilityDescription}</div>
      <div className={styles.abilityBar}>
        <img
          src={qImage}
          className={styles.abilityImage}
          onMouseOver={() => onAbilityHover(AbilityConstants.Q)}
        ></img>
        <img
          src={eImage}
          className={styles.abilityImage}
          onMouseOver={() => onAbilityHover(AbilityConstants.E)}
        ></img>
        <img
          src={rImage}
          className={styles.abilityImage}
          onMouseOver={() => onAbilityHover(AbilityConstants.R)}
        ></img>
      </div>
    </div>
  );
}

export default ChampionBio;
