import { UPDATE_GAME_STATE, CLEAR_PLAYERS_IN_GAME_STATE} from "../constants/gameState-types";

export function updateGameState(state){
    return {
        type: UPDATE_GAME_STATE,
        gameState: state,
    }
}

export function clearPlayersInGameState(){
    return {
        type: CLEAR_PLAYERS_IN_GAME_STATE,
    }
}