import styles from "./ExpBar.module.css";
import gsap from "gsap";
import { useState, useEffect, useRef } from "react";

export default function ExpBar({ score }) {
  //// Character Stats Calc
  const expRequirementConstant = 200;
  const [level, setLevel] = useState(1);
  const [experience, setExperience] = useState(score);
  const [expRequirement, setExpRequirement] = useState(
    Math.round(expRequirementConstant * 1.25 * 1)
  );
  // Difference Detection
  const previousScoreRef = useRef(score);

  // Character Stats Calc
  useEffect(() => {
    let loopLevel = 1;
    let loopExp = score;
    //Todo: Should evolve this leveling requirement as the game develops.
    let loopExpReq = Math.round(expRequirementConstant * 1.25 * 1);

    for (let i = loopLevel; i < 30; i++) {
      if (loopExp >= loopExpReq) {
        loopLevel += 1;
        loopExp -= loopExpReq;
        loopExpReq = Math.round(
          expRequirementConstant * 1.25 + loopLevel * 110
        );
      } else {
        break;
      }
    }
    if (loopLevel >= 30) {
      loopExp = Math.min(loopExp, loopExpReq);
    }

    setLevel(loopLevel);
    setExperience(loopExp);
    setExpRequirement(loopExpReq);
  }, [score]);

  // Transition Calcs
  //// useEffect only occurs directly after score change updates directly finish. 
  //// Calculate the amount of level-ups from the past score & the new score.
  //// Trigger transitions from CurrentExp -> 100 -> 0 per level up.
  //// Then finally go to the leftover exp.
  const [meterPercent, setMeterPercent] = useState(
    (experience / expRequirement) * 100
  );
  let proxy = useRef({ meterPercent });
  const styleMeterPercent = `${meterPercent}%`;

  useEffect(() => {
    function calculateLevelUpNumber(oldScore, newScore) {
      let oldScoreLevel = 1;
      let newScoreLevel = 1;
      let loopExpReq = Math.round(expRequirementConstant * 1.25 + 1 * 110);
      for (let i = 1; i < 30; i++) {
        if (oldScore >= loopExpReq) {
          oldScoreLevel += 1;
          oldScore -= loopExpReq;
          loopExpReq = Math.round(expRequirementConstant * 1.25 + i * 110);
        }
        if (newScore >= loopExpReq) {
          newScoreLevel += 1;
          newScore -= loopExpReq;
          loopExpReq = Math.round(expRequirementConstant * 1.25 + i * 110);
        } else {
          break;
        }
      }
      return newScoreLevel - oldScoreLevel;
    }
    let levelUpNumber = calculateLevelUpNumber(previousScoreRef.current, score);

    var tl = gsap.timeline();
    for (let i = 0; i < levelUpNumber; i++) {
      tl.to(proxy.current, {
        meterPercent: 100,
        onUpdate: () => {
          setMeterPercent(proxy.current.meterPercent);
        },
        ease: "power1.out",
      });
      tl.to(proxy.current, {
        meterPercent: 0,
        onUpdate: () => {
          setMeterPercent(proxy.current.meterPercent);
        },
        ease: "power1.out",
        duration: 0,
      });
    }
    tl.to(proxy.current, {
      meterPercent: (experience / expRequirement) * 100,
      onUpdate: () => {
        setMeterPercent(proxy.current.meterPercent);
      },
      ease: "power1.out",
    });

    // Difference Detection
    previousScoreRef.current = score;
  }, [experience, expRequirement]);

  /// Cleanup Function
  useEffect(() => {
    return () => {
      setLevel(1);
      setExperience(0);
      setExpRequirement(expRequirementConstant * 1.25 * 1);
      previousScoreRef.current = 0;
    };
  }, []);

  /// function return

  return (
    <div className={styles.container}>
      <div style={{ width: styleMeterPercent }} className={styles.gloss}></div>
      <div className={styles.label}>EXP</div>
      <div
        className={styles.meter}
      >{`Level ${level}: ${experience}/${expRequirement}`}</div>
    </div>
  );
}
