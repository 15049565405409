import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants";

import jirqoImage from "../../../assets/champions/jirqo/avatar.png";
import jirqoDrawInfo from "./drawings/jirqoDrawInfo";
import attackAnimation from "./animations/attackAnimation";
import eAnimation from "./animations/eAnimation";
import deathAnimation from "../damageAnimations/deathAnimation";
import jirqoAbilities from "./abilities/jirqoAbilities";


export const jirqoInfo = {
  drawing: jirqoDrawInfo,
  animations: {
    [ObjectStateConstants.ATTACKING]: attackAnimation,
    [ObjectStateConstants.CASTING_Q]: attackAnimation,
    [ObjectStateConstants.CASTING_E]: eAnimation,
    [ObjectStateConstants.CASTING_R]: eAnimation,
    [ObjectStateConstants.STUNNED]: { animation: [] },
    [ObjectStateConstants.DEATH]: deathAnimation,
  },
  abilities: jirqoAbilities,
  image: jirqoImage,
  name: "Jirqo",
  biography: `With a single arrow, Jirqo felled the reigning warlord. But the lord survived, and Jirqo has been sighted within
  the Terrace. Some speculate Jirqo is in hiding or in exile.`,
};

export default jirqoInfo;
