import strangerImage from "../../../assets/strangerSmudged.png"
import futureAbilities from "./abilities/futureAbilities";

export const futureChampion2 = {
  drawing: {},
  animations: {},
  abilities: futureAbilities,
  image: strangerImage,
  name: "Locked",
  biography: `...`,
  locked: true,
};

export default futureChampion2;
