//Use variable bc positions can be auto-calculated
//Based off the root handle.


const shieldInfo = [
  {
    name: "shield",
    type: "polygon",
    points: [
      { x: -40, y: 40 },
      { x: -56, y: 56 },
      { x: -72, y: 0 },
      { x: -56, y: -56 },
      { x: -40, y: -40 },
      { x: -56, y: 0 },
      { x: -40, y: 40 },
    ],
    xTrans: -8,
    yTrans: 0,
    fillStyle: "#505E5B",
    lineWidth: 10,
    strokeStyle: "#000000",
    rotation: (Math.PI * 90) / 180,
  },
];

export default shieldInfo;
