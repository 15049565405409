
const radius = 60;
const octagonPoints = []
for (let i = 0; i<9 ;i++){
    let points =  {x:0,y:0}
    let angle =  Math.PI/4 * i;
    points.x = radius * Math.cos(angle)
    points.y = radius * Math.sin(angle);
    octagonPoints.push(points);
}

const eyeWidth = radius*0.35
const eyeHeight = radius*0.15
const eyeYDistance = radius*0.45
const eyeXDistance = radius*0.33
const bouldaDrawing = {
  shapes: [
    {
      name: "body",
      type: "polygon",
      points: octagonPoints,
      xTrans: 0,
      yTrans: 0,
      fillStyle: "#666688",
      lineWidth: 12,
      strokeStyle: "#000000",
      rotation: (Math.PI * (-22.5 + -90)) / 180,
    },
    {
      name: "eye1",
      type: "rectangle",
      x: -eyeWidth / 2 + eyeXDistance,
      y: eyeYDistance,
      width: eyeWidth,
      height: eyeHeight,
      fillStyle: "#333333",
      lineWidth: 6,
      strokeStyle: "#000000",
      rotation: (Math.PI * (-10 + -90)) / 180,
    },
    {
      name: "eye2",
      type: "rectangle",
      x: -eyeWidth / 2 - eyeXDistance,
      y: eyeYDistance,
      width: eyeWidth,
      height: eyeHeight,
      fillStyle: "#333333",
      lineWidth: 6,
      strokeStyle: "#000000",
      rotation: (Math.PI * (10 + -90)) / 180,
    },
  ],
};
export default bouldaDrawing;