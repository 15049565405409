
import styles from "./UserHud.module.css"
import Ability from "./Ability/Ability";
import HealthBar from "./StatusBars/HealthBar/HealthBar.js"
import ExpBar from "./StatusBars/ExpBar/ExpBar.js"
import Map from "./Map/Map";
import attackIcon from "../../assets/sprites/attackIcon.png"
import allChampions from "../../game/champions/champions.js"
import { AbilityConstants }  from "../../constants/AbilitySystemConstants.js"
import { useSelector } from "react-redux";

export default function UserHud() {

    let gameState = useSelector((state) => state.game.gameState);
    let score = useSelector((state)=> state.game.gameState.me.score)
    //Map Values
    let xPos = gameState.me.pos.x;
    let yPos = gameState.me.pos.y;

    //Champion Images Retrieval
    const selectedChampion = useSelector(
      (state) => state.championSelect.selectedChampion
    );
    const qImage = allChampions[selectedChampion].abilities[AbilityConstants.Q].image
    const eImage = allChampions[selectedChampion].abilities[AbilityConstants.E].image
    const rImage = allChampions[selectedChampion].abilities[AbilityConstants.R].image

    // healthbar Values
    const health = Math.floor(gameState.me.health);

  
  return (
    <div className={styles.wrapper}>
      <Ability
        awayNum={0}
        imageSource={attackIcon}
        text={"Attack"}
        ability={AbilityConstants.ATTACK}
      />
      <Ability
        awayNum={1}
        imageSource={qImage}
        text={"Q"}
        ability={AbilityConstants.Q}
      />
      <Ability
        awayNum={2}
        imageSource={eImage}
        text={"E"}
        ability={AbilityConstants.E}
      />
      <Ability
        awayNum={3}
        imageSource={rImage}
        text={"R"}
        ability={AbilityConstants.R}
      />
      <HealthBar health={health} maxHealth={100} />
      <ExpBar isExperience score={score} />
      <Map xPos={xPos} yPos={yPos} />
    </div>
  );
}
