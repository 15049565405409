import victoriousImage from "../../../assets/victorious.jpg"
import futureAbilities from "./abilities/futureAbilities";

export const futureChampion3 = {
  drawing: {},
  animations: {},
  abilities: futureAbilities,
  image: victoriousImage,
  name: "Locked",
  biography: `...`,
  locked: true,
};

export default futureChampion3;
