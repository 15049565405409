
import renderChampion from "./renderChampion";
import renderEnemy from "./renderEnemy";
import renderProjectile from "./renderProjectile";
import renderTrail from "./renderTrail";
import renderEnvironment from "./renderBiome";
import { playerAnimationManager, enemyAnimationManager, projectileAnimationManager, trailAnimationManager } from "../model/AnimationManager";
import store from "../../redux/store";
import trailManager from "../model/TrailManager";


async function processGameLoop(canvasElement) {
  updateAnimationManagers();
  renderView(canvasElement);
  //Loop
  requestAnimationFrame(() => processGameLoop(canvasElement));
}
function renderView(canvasElement) {
  const gameState = store.getState().game.gameState;
  const ctx = canvasElement.getContext("2d");
  let activePlayer = gameState.me;
  let players = gameState.players;
  let projectiles = gameState.projectiles;
  let enemies = gameState.enemies;

  ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
  //Map Follow
  centerCanvasOnPlayer(canvasElement, activePlayer);
  //Environment
  renderEnvironment(canvasElement, activePlayer);
  //Trails
  const trails = trailManager.getTrails();
  trails.forEach((trail) => {
    renderTrail(ctx, trail);
  });
  // Projectiles
  projectiles.forEach((proj) => {
    renderProjectile(ctx, proj);
    // trailManager.addCircleTrail(proj.pos);
  });
  // Enemies
  enemies.forEach((enemy) => {
    renderEnemy(ctx, enemy);
  });
  // Players
  players.forEach((player) => {
    renderChampion(ctx, player);
  });

  //Map Follow End
  uncenterCanvasFromPlayer(canvasElement, activePlayer);
}
function updateAnimationManagers(){
  //todo: this one isn't an animation manager but its ok
  trailManager.update();
  playerAnimationManager.update();
  enemyAnimationManager.update();
  projectileAnimationManager.update();
  trailAnimationManager.update();
}
function centerCanvasOnPlayer(canvasElement, player) {
  const ctx = canvasElement.getContext("2d");
  ctx.translate(
    -player.pos.x + canvasElement.width / 2,
    -player.pos.y + canvasElement.height / 2
  );
}
function uncenterCanvasFromPlayer(canvasElement, player) {
  const ctx = canvasElement.getContext("2d");
  ctx.translate(
    player.pos.x - canvasElement.width / 2,
    player.pos.y - canvasElement.height / 2
  );
}



export default processGameLoop