import styles from "./GameLeaderboard.module.css"
import {useSelector} from "react-redux"

export default function GameLeaderboard(){
    let gameState = useSelector((state)=>state.game.gameState);
    //todo: this will slow down game a lot 
    // in future top 10 players needs to be sent separately to be used
    // When we add delta updates we might only need to send this top 10 list once. 
    let scoreSortedPlayers = [...gameState.players].sort((p1,p2)=>p2.score - p1.score)



    const createEntry = (player, i) => {
      let playerName = player.playerName;

      return (
        <div className={styles.LBEntry}>
          <div className={styles.entry1}>{i+1}</div>
          <div className={styles.entry2}
          >{playerName}</div>
          <div>{player.score}</div>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.LBHeaders}>
          <div className={styles.entry1}>Rank</div>
          <div className={styles.entry2}>Name</div>
          <div>Score</div>
        </div>
        {gameState
          ? scoreSortedPlayers.map((player, i) => createEntry(player, i))
          : null}
      </div>
    );
}