import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants.js";
import {
  AbilityConstants,
  DynamicInputs,
} from "../../../constants/AbilitySystemConstants.js";

import boarAttackAnimation from "./animations/boarAttackAnimation.js";
import eAnimation from "./animations/eAnimation.js";
import ultimateAnimation from "./animations/ultimateAnimation.js";
import deathAnimation from "../damageAnimations/deathAnimation.js";
import drawLuccaBoarInfo from "./drawings/drawLuccaBoar.js";
import luccaImage from "../../../assets/champions/lucca/avatar.png"
import luccaAbilities from "./abilities/luccaAbilities.js";

const luccaBoarInfo = {
  drawing: drawLuccaBoarInfo,
  animations: {
    [ObjectStateConstants.ATTACKING]: boarAttackAnimation,
    [ObjectStateConstants.CASTING_Q]: { animation: [] },
    [ObjectStateConstants.CASTING_E]: eAnimation,
    [ObjectStateConstants.CASTING_R]: ultimateAnimation,
    [ObjectStateConstants.STUNNED]: { animation: [] },
    [ObjectStateConstants.DEATH] : deathAnimation,
  },
  abilities: luccaAbilities,
  image: luccaImage,
  name: "Lucca",
  biography:
    `The king tasked Lucca with nine trials. 
    Through seven, he succeeded with no injury. 
    Through the eighth, he received a scratch. 
    And the ninth trial required that Lucca venture down into the Terrace.`,
};

export default luccaBoarInfo;
