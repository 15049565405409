import drawShapeWithAnimators from "./drawShapeWithAnimators";
import allTrails from "../trails/allTrails";
import { trailAnimationManager } from "../model/AnimationManager";
import trailManager from "../model/TrailManager";
import { alignCanvasOnObject, unalignCanvasFromObject } from "./utility/renderUtilities";

function renderTrail(ctx, trail) {
  let trailAnimator = trailAnimationManager.getAnimator(
    trail.id
  );
  alignCanvasOnObject(ctx, trail);
  const trailInfo = allTrails[trail.objectName];
  for (let shape of trailInfo.drawing.shapes) {
    drawShapeWithAnimators(ctx, shape, trailAnimator);
  }
  unalignCanvasFromObject(ctx, trail);
}

export default renderTrail;
