import styles from "./AbilityClick.module.css";
import { useEffect, useRef, useState } from "react";
import {gsap} from "gsap";
import { AbilityConstants } from "../../../constants/AbilitySystemConstants";

export default function AbilityClick({abilityKey}) {
    const [percent, setPercent] = useState(0);
    const proxy = useRef({ percent });

    function triggerAnimation(){
        gsap.to(proxy.current, {
        percent: 100,
        onUpdate: () => {
            setPercent(proxy.current.percent);
        },
        onComplete: () => {
            proxy.current.percent = 0;
            setPercent(proxy.current.percent);
        },
        ease: "power2.out",
        });
    }
    useEffect(() => {
        const handleKeyDown = (event) => {
            // Trigger for Ability Keys
            if (event.key === abilityKey.toLowerCase()) {
              triggerAnimation();
            }
            // Trigger for Clicks Too
            else if (
              event.type === "click" &&
              abilityKey == AbilityConstants.ATTACK
            ) {
              triggerAnimation();
            }
        };
        // Bind the event listener to the window. Based on mouseclick / keydown
        if (abilityKey == AbilityConstants.ATTACK){
            window.addEventListener("click", handleKeyDown);
        }
        else {
            window.addEventListener("keydown", handleKeyDown);
        }
        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("click", handleKeyDown);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div
                style={{
                width: `${Math.round((percent * 91) / 100)}%`,
                height: `${Math.round((percent * 91) / 100)}%`,
                opacity: 1.0 - Math.round(percent*1.0)/100,
                }}
                className={styles.cooldownCircle}
            ></div>
        </div>
    );
}
