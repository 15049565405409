import { AbilityConstants } from "../../../constants/AbilitySystemConstants";
import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants";

const sullenInfo = {
  drawing: {},
  animations: {},
  abilities: {},
};

export default sullenInfo;
