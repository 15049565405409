import drawShapeWithAnimators from "./drawShapeWithAnimators";
import allProjectiles from "../projectiles/projectiles";
import renderHitbox from "./renderHitbox";
import { projectileAnimationManager } from "../model/AnimationManager";
import trailManager from "../model/TrailManager";
import { alignCanvasOnObject, unalignCanvasFromObject } from "./utility/renderUtilities";

function renderProjectile(ctx, serverProjData){
    let projectileAnimator = projectileAnimationManager.getAnimator(serverProjData.id)
    alignCanvasOnObject(ctx, serverProjData);
    const clientProjData = allProjectiles[serverProjData.objectName];
    //todo: should be some sort of user/admin setting for this
    // renderHitbox(ctx, clientProjData);
    for (let shape of clientProjData.drawing.shapes){
      drawShapeWithAnimators(ctx, shape, projectileAnimator);
    }
    unalignCanvasFromObject(ctx, serverProjData);
}

export default renderProjectile